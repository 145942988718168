.purchase-main-wrapper {
  min-height: calc(100vh - 64px);
  margin-top: 64px;
  display: flex;
  flex-direction: column;
}

.purchase-wrapper {
  padding: 2rem 0;
}

.purchase-title {
  text-align: center;
  margin-bottom: 3rem;
}

.image-display-purchase img {
  width: 100%;
  max-height: 315px;
  object-fit: cover;
  object-position: center;
}

.back-to-results-btn {
  display: inline-block;
  cursor: pointer;
  color: #333333;
}

.back-to-results-btn:hover {
  text-decoration: underline;
}

.invoice-wrapper-inner {
  background-color: #f2f2f2;
  padding: 1.5rem 3rem;
  margin-top: 2rem;
}

.purchase-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.purchase-invoice-title {
  text-align: center;
  margin-bottom: 2rem;
}

.purchase-item-details p {
  font-size: 14px;
  margin-bottom: 0;
}

.purchase-invoice-total-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  border-top: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  padding: 8px 0;
}

.purchase-item-promo-code {
  margin-bottom: 1.5rem;
}

.purchase-item-promo-code {
  margin-bottom: 1.5rem;
  color: #eb5757;
  font-weight: 600;
  cursor: pointer;
}

.purchase-item-promo-code:hover {
  text-decoration: underline;
}

.purchase-inner .purchase-license-note {
  text-align: right;
}

.invoice-wrapper {
  margin-bottom: 1rem;
}

.payment-method-wrapper {
  margin-top: 2.5rem;
}

.purchase-user-details-title {
  margin-bottom: 1rem;
}

.purchase-action-container .button-regular.purchase-btn svg {
  vertical-align: -2px;
  margin-right: 0.5rem;
}

.payment-methods-icon-inner {
  display: flex;
  justify-content: center;
}

.payment-methods-icon-inner img {
  width: 50px;
  margin: 0 5px;
}

.payment-methods-icon-wrapper {
  text-align: center;
  margin-top: 5rem;
}

.payment-methods-icon-wrapper h6 {
  font-weight: 400;
}

@media only screen and (max-width: 992px) {
  .purchase-description-wrapper {
    margin-top: 1rem;
  }

  .payment-methods-icon-wrapper {
    margin-top: 3rem;
  }

  .purchase-action-container .button-regular.purchase-btn {
    margin-top: 0;
  }
}

@media only screen and (max-width: 768px) {
  .image-display-purchase {
    text-align: center;
  }
  .invoice-wrapper {
    margin-bottom: 2rem;
  }
  .invoice-wrapper-inner {
    padding: 1rem 0.5rem;
    margin-top: 1rem;
  }
  .purchase-item-details h6 {
    margin: 0;
  }
  .purchase-title {
    font-size: 18px;
  }
  .purchase-invoice-title {
    font-size: 17px;
  }
  .purchase-wrapper {
    padding: 0 0 1rem 0;
  }
  .search-results-page {
    margin-bottom: 0;
  }
}
