.navbar {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  flex-wrap: nowrap !important;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  -webkit-transition: all 1.2s cubic-bezier(0.22, 0.44, 0, 1);
  -o-transition: all 1.2s cubic-bezier(0.22, 0.44, 0, 1);
  transition: all 1.2s cubic-bezier(0.22, 0.44, 0, 1);
  background-image: linear-gradient(
    to right,
    rgb(255 255 255 / 25%),
    rgb(0 0 0 / 51%)
  );
}

.navbar-bg {
  background: #303030;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-bottom: 0;
}

.nav-item {
  display: flex;
  align-items: center;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 17px;
}

.nav-links:hover {
  background-color: #ca3f13;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  color: #ffffff !important;
  text-decoration: none;
}

.nav-link a {
  color: #ffffff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.nav-logo-sec {
  display: flex;
  align-items: center;
}

.nav-button {
  padding: 8px 20px !important;
}

.nav-logo-sec .home-link {
  margin-left: 1rem;
}

.nav-logo-sec .home-link a {
  font-size: 19px;
  line-height: 1;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
}

.nav-logo-sec .home-link a:hover {
  color: #ffffff;
}

#user-profile-dropdown {
  background-color: transparent;
  border-color: transparent;
  font-size: 17px;
  padding: 0;
  color: #ffffff;
}

.navbar-bg .nav-links {
  /* color: #212529; */
}

.navbar-bg .nav-links button {
  color: #212529;
}

.navbar-bg .nav-links button:hover {
  color: #fff;
}

.nav-links.color-dark.dropdown:hover,
.nav-links.dropdown:hover {
  background-color: #ca3f13;
  text-decoration: unset;
  color: #ffffff;
}

#user-profile-dropdown:focus {
  box-shadow: none;
  background-color: transparent;
}

.nav-links.color-dark.dropdown #user-profile-dropdown {
  color: #333333;
}

.nav-links.color-dark.dropdown:hover #user-profile-dropdown {
  color: #ffffff;
}

.nav-links.dropdown {
  background-color: transparent;
}

.profile-drop-down-nav-link {
  background-color: #494949 !important;
  border-radius: 4px;
}

.profile-drop-down-nav-link:hover {
  background-color: #2c3134 !important;
}

.home-item {
  display: none;
}

.photographer-profile-option {
  border-radius: 50px;
  border-color: #e64a19 !important;
  border-style: solid !important;
  border-width: 2px !important;
  margin: auto !important;
  text-align: center !important;
  width: 80% !important;
}

div.sb-avatar__text div span {
  padding-top: 10px;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .home-item {
    display: block;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 64px);
    position: absolute;
    top: 64px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding: 2rem;
    justify-content: center;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 2000;
  }

  .nav-button {
    margin: 2rem;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 35%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 20%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #ffffff;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #ca3f13;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #ca3f13;
    transition: 250ms;
  }

  .nav-links.color-dark.dropdown:hover,
  .nav-links.dropdown:hover {
    background-color: transparent;
  }

  .navbar {
    justify-content: flex-start !important;
  }
  .nav-menu {
    height: calc(100vh - 55px);
    top: 55px;
  }
}

@media screen and (max-width: 450px) {
  .nav-logo-sec .home-link {
    display: none;
  }
}

.color-dark {
  color: #333333 !important;
}

.nav-item .color-dark:hover {
  color: #ffffff !important;
}

.nav-dropshadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background: #303030;
}

.nav-dropshadow .nav-links {
  color: #ffffff;
}

.nav-dropshadow .nav-links button {
  color: #212529;
}

.nav-dropshadow .nav-links:hover button {
  color: #fff;
}

#nav-search.image-search-error {
  border: 1px solid rgb(220 53 69 / 43%) !important;
}

/* #nav-search {
  background-color: #e0e0e0 !important;
} */

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

@media (max-width: 960px) {
  .nav-dropshadow .nav-links {
    color: #fff !important;
  }

  .nav-dropshadow .nav-links button {
    color: #fff !important;
  }

  .navbar-bg .nav-links {
    color: #fff !important;
  }

  .navbar-bg .nav-links button {
    color: #fff !important;
  }
}
