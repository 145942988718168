.search-results-wrapper,
.photo-detail-wrapper {
  min-height: calc(100vh - 64px);
  margin-top: 64px;
  display: flex;
  flex-direction: column;
}

/* RESULTS MASONRY */
* .results-masonry {
  margin-left: auto;
  margin-right: auto;
  column-gap: 10px;
  box-sizing: border-box;
}

.masonry {
  height: auto;
  padding-top: 3rem;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
  grid-auto-rows: 0;
  max-width: 1280px;
  margin: auto;
  grid-column-gap: 16px !important;
  grid-row-gap: 10px !important;
}

.masonry-brick {
  max-width: 300px;
}

@media only screen and (max-width: 1440px) {
  .masonry {
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  }
}

@media only screen and (max-width: 1280px) {
  .masonry {
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  }
}

@media only screen and (max-width: 900px) {
  .masonry-modal .image-container {
    width: 90%;
  }
  .masonry-modal .image-container img {
    width: 100%;
  }
  .masonry {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }
}

@media only screen and (max-width: 768px) {
  .masonry {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    padding-top: 1.5rem;
  }
}

@media only screen and (max-width: 576px) {
  .masonry {
    grid-template-columns: repeat(auto-fill, minmax(47%, 1fr));
    padding-top: 1rem;
  }
}


.results-masonry-img {
  width: 100%;
  border-radius: 5px;
}

.results-masonry-item {
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  -webkit-mask: linear-gradient(to bottom, black 0% 33%, transparent 66% 100%) 0
    100%/100% 300%;
  mask: linear-gradient(to bottom, black 0% 33%, transparent 66% 100%) 0 100%/100%
    300%;
  -webkit-animation: mask-slide-in 1.2s calc(0.2s + 0.1s * 1) forwards;
  animation: mask-slide-in 1.2s calc(0.2s + 0.1s * 1) forwards;
}

@-webkit-keyframes mask-slide-in {
  from {
    transform: scale(0.9) translateY(10vw);
    -webkit-mask-position: 0 100%;
    mask-position: 0 100%;
  }
  to {
    transform: scale(1) translateY(0);
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

@keyframes mask-slide-in {
  from {
    transform: scale(0.9) translateY(10vw);
    -webkit-mask-position: 0 100%;
    mask-position: 0 100%;
  }
  to {
    transform: scale(1) translateY(0);
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}
.js-loading *,
.js-loading *::before,
.js-loading *::after {
  -webkit-animation-play-state: paused !important;
  animation-play-state: paused !important;
}

/* END RESULTS MASONRY */

.search-results-page {
  padding: 2rem 0;
  width: 95%;
  margin: auto;
  margin-bottom: 3rem;
  margin-top: 0;
}

.horizontal {
  grid-column: span 2;
}

.big {
  grid-column: span 2;
  grid-row: span 2;
}

.search.query-display {
  text-align: center;
  margin-bottom: 3rem;
}

.result-info-bar {
  position: absolute;
  bottom: -100px;
  width: 100%;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 10px 10px 5px 5px;
  padding: 10px;
  color: #ffffff;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}

.results-masonry-item:hover .result-info-bar {
  bottom: 0;
}

.result-info-bar h6 {
  font-weight: 400;
}

.photo-detail-inner-wrapper {
  padding: 2rem 0;
}

.photo-detail-inner {
  margin-top: 1rem;
}

.photo-detail-using-count {
  display: flex;
}

.photo-detail-header-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.download-count {
  margin-right: 0.5rem;
  background-color: #e0e0e0;
  color: #333333;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 500;
}

.download-count span {
  margin-right: 5px;
}

.download-count span svg {
  font-size: 17px;
}

.photo-details-uploader {
  color: #666666;
  font-weight: 500;
}

.purchase-image-wrapper {
  margin-top: 1rem;
}

.purchase-license-note {
  font-size: 14px;
  margin-top: 0.5rem;
}

.button-regular.purchase-btn {
  padding: 6px 60px;
  font-size: 18px;
}

.pd-description-wrapper p,
.pd-category-wrapper p {
  font-size: 15px;
}

.pd-metadata-wrapper {
  background-color: #f2f2f2;
  display: inline-block;
  padding: 15px;
  font-size: 15px;
  min-width: 320px;
}

.pd-metadata-full-details table {
  width: 100%;
  font-size: 15px;
  font-weight: 500;
}

.pd-metadata-full-details table tr td:nth-child(2) {
  text-align: right;
}

.pd-metadata-full-details table tr {
  border-bottom: 1px solid #ececec;
}

.pd-metadata-full-details table tr td {
  padding: 3px 0px;
}

.pd-metadata-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.pd-metadata-header-icon {
  width: 45px;
  margin-right: 1rem;
}

.pd-metadata-header-info-title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 2px;
}

.pd-metadata-header-info-subinfo {
  font-size: 13px;
  font-weight: 500;
  color: #545b62;
}

.pd-keyword-item {
  background-color: #e0e0e0;
  display: inline-block;
  padding: 3px 20px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.pd-keywords-wrapper {
  margin: 0.7rem 0;
}

.pd-keywords {
  margin-top: 1.5rem;
}

input[name="searchTags"] {
  width: 30px;
}

.image-loading-display {
  display: flex;
  justify-content: center;
  height: 241px;
  align-items: center;
  border: solid #f2f2f2;
  border-radius: 10px;
  border-width: 1px;
}

@media screen and (min-width: 1200px) {
  .results-masonry {
    columns: 6;
  }
}
@media (min-width: 993px) and (max-width: 1199px) {
  .results-masonry {
    columns: 4;
  }
}
@media (min-width: 600px) and (max-width: 992px) {
  .results-masonry {
    columns: 3;
  }
}

@media screen and (max-width: 992px) {
  .image-display {
    text-align: center;
  }

  .pd-category-wrapper {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  .pd-category-wrapper h6 {
    margin-right: 5px;
  }

  .pd-metadata-wrapper {
    display: block;
  }

  .photo-detail-inner {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .purchase-image-wrapper {
    text-align: center;
  }
  .search.query-display {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .photo-detail-inner-wrapper {
    padding: 0 0 1rem 0;
  }
  .pd-metadata-wrapper {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 599px) {
  .results-masonry {
    columns: 2;
  }
}
