.success-error-div {
  position: absolute;
  width: 450px;
  margin: auto;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.success-error-div .icon {
  display: block;
  font-size: 60px;
  margin: auto;
}

.success-error-div .icon-success {
  color: #4caf50;
}

.success-error-div .icon-error {
  color: #f5857d;
}

.success-error-div p {
  text-align: center;
}

.success-error-div .msg-heading {
  font-size: 25px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 8px;
}

.success-error-div .msg-body {
  font-size: 15px;
}

.success-error-div .download-btn {
  width: 150px;
  margin: auto;
  display: block;
  margin-top: 36px;
}

@media (max-width: 575px) {
  .success-error-div {
    width: 300px;
  }
}
