:root {
  --padding-offset: 2rem;
  --transition-duration-300: 300ms;
  --transition-duration-100: 100ms;
  --transition-delay: 80ms;
  --body-background: rgb(225, 225, 225);
  --white: #fff;
  --black: #000;
  --gray-light: #6d6d6d;
  --gray-dark: #494949;
  --blue-tint-light: hsl(216, 100%, 50%);
  --blue-tint-dark: hsl(216, 100%, 35%);
}

.masonry {
  height: auto;
  padding-top: 3rem;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
  grid-auto-rows: 0;
  /* max-width: 95%; */
  max-width: 1280px;
  margin: auto;
  grid-column-gap: 16px !important;
  grid-row-gap: 10px !important;
}

.masonry .masonry-brick .image-card {
  --card-options-height: 60px;
  position: relative;
  display: flex;
  background: var(--white);
  box-shadow: 0 6.7px 5.3px rgba(0, 0, 0, 0.016),
    0 22.3px 17.9px rgba(0, 0, 0, 0.024), 0 100px 80px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}
.masonry .masonry-brick .image-card::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.75) 0%, transparent 100%);
  opacity: 0;
  transition: opacity var(--transition-duration-300) ease;
  transition-delay: var(--transition-delay);
  z-index: 1;
}
.masonry .masonry-brick .image-card:hover::after {
  opacity: 1;
}
.masonry .masonry-brick .image-card:hover img.image {
  --image-transform-offset: -30px;
}
.masonry .masonry-brick .image-card:hover .image-card--options {
  --tranform-y: 0;
}
.masonry .masonry-brick .image-card img.image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  /* transition: transform var(--transition-duration-300) ease;
  transform: translateY(var(--image-transform-offset, 0%));
  transition-delay: var(--transition-delay); */
}
.masonry .masonry-brick .image-card--clickable-area {
  position: absolute;
  /* position: fixed; */
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - var(--card-options-height));
  z-index: 20;
  cursor: pointer;
}
.masonry .masonry-brick .image-card--options {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  grid-template-columns: 1fr 0.35fr;
  align-items: center;
  background: var(--white);
  transition: transform var(--transition-duration-300) ease;
  transform: translateY(var(--tranform-y, 100%));
  transition-delay: var(--transition-delay);
  z-index: 5;
  cursor: default;
  background: transparent;
}
.masonry .masonry-brick .image-card--options .user-info {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  justify-content: space-between;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 4px;
}
.masonry .masonry-brick .image-card--options .user-info .download-icon-div {
  margin-left: 10px;
}
.masonry .masonry-brick .image-card--options .user-info .user-name-div {
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 82px;
  white-space: nowrap;
  margin-top: auto;
  margin-bottom: auto;
}
/* .masonry .masonry-brick .image-card--options .user-info a {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
} */
.masonry .masonry-brick .image-card--options .user-info img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  /* width: 100%;
  height: 100%; */
  -o-object-fit: cover;
  object-fit: cover;
}
.masonry .masonry-brick .image-card--options .user-info h3 {
  margin-left: 6px;
  font-size: 0.8em;
  color: #333333;
  padding-top: 5px;
}
.masonry .masonry-brick .image-card--options .links {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.8em;
}
.masonry .masonry-brick .image-card--options .links .link .icon {
  --size: 28px;
  width: var(--size);
  height: var(--size);
  margin-left: 12px;
  padding-right: 10px;
}
.masonry .masonry-brick .image-card--options .links .link .icon svg {
  fill: var(--fill, rgba(0, 0, 0, 0.6));
  width: 100%;
  height: 100%;
  transition: fill var(--transition-duration-100) ease;
}
.masonry
  .masonry-brick
  .image-card--options
  .links
  .link:hover
  .icon.instagram {
  --fill: #fb3958;
}
.masonry .masonry-brick .image-card--options .links .link:hover .icon.twitter {
  --fill: #00acee;
}
.masonry .masonry-brick .image-card--options .links .link:hover .icon.download {
  --fill: var(--black);
}
.masonry .masonry-brick .image-card .image-card-fg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform-origin: left center;
  transform: scaleX(1);
  transition: transform var(--transition-duration-300) ease;
  pointer-events: none;
}
.masonry .masonry-brick .image-card .image-card-fg.hide {
  transform-origin: right center;
  transform: scaleX(0);
}

.masonry-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 35;
  background: rgba(0, 0, 0, 0.9);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
.masonry-modal .image-container {
  --icon-size: 20px;
  position: relative;
  width: auto;
  margin-top: -2rem;
}
.masonry-modal .image-container img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.masonry-modal .image-container button.modal-close {
  position: absolute;
  top: 0;
  right: calc(-2 * var(--icon-size));
  transform: translate(-50%, -50%);
  width: calc(var(--icon-size) * 2);
  height: calc(var(--icon-size) * 2);
  background: #ff4f4f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  font-size: small;
}
.masonry-modal .image-container button.modal-close:focus,
.masonry .masonry-brick .image-card--options .links .link .icon:focus {
  outline: 0;
}

.masonry-modal .image-container .icon {
  width: calc(var(--icon-size));
  height: calc(var(--icon-size));
}
.masonry-modal .image-container .icon svg {
  fill: var(--white);
  width: 100%;
  height: 100%;
}
.masonry-modal .image-container .options--container {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--white);
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}
.masonry-modal .image-container .options--container .icon svg {
  fill: var(--gray-light);
  vertical-align: baseline;
}
.masonry-modal .image-container .options--container .group {
  display: flex;
  height: 32px;
  margin: 0 10px;
}
.masonry-modal .image-container .options--container .group a,
.masonry-modal .image-container .options--container .group button {
  --border-color: rgba(0, 0, 0, 0.25);
  color: var(--gray-light);
  font-size: 0.85rem;
  text-transform: capitalize;
  font-weight: 800;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--border-color);
  background: var(--white);
  border-radius: 4px;
  transition: border var(--transition-duration-100) ease,
    color var(--transition-duration-100) ease;
}
.masonry-modal .image-container .options--container .group a:hover,
.masonry-modal .image-container .options--container .group button:hover {
  --border-color: rgba(0, 0, 0, 1);
  border: 1px solid var(--border-color);
  color: var(--black);
}
.masonry-modal .image-container .options--container .group a:hover .icon svg,
.masonry-modal
  .image-container
  .options--container
  .group
  button:hover
  .icon
  svg {
  fill: var(--black);
}
.masonry-modal .image-container .options--container .group.g-1 a {
  text-decoration: none;
  padding: 0 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.masonry-modal
  .image-container
  .options--container
  .group.g-1
  .dropdown-btn--container {
  position: relative;
  width: var(--size);
  height: var(--size);
}
.masonry-modal
  .image-container
  .options--container
  .group.g-1
  .dropdown-btn--container
  button {
  width: 100%;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.masonry-modal
  .image-container
  .options--container
  .group.g-1
  .dropdown-btn--container
  button
  .icon {
  transform: rotate(180deg);
}
.masonry-modal
  .image-container
  .options--container
  .group.g-1
  .dropdown-btn--container
  .dropdown--menu {
  position: absolute;
  right: 5%;
  bottom: 135%;
  background: var(--black);
  width: 240px;
  border-radius: 0.45rem;
  transform-origin: 95% 115%;
  transform: scale(1);
  transition: transform var(--transition-duration-300)
      cubic-bezier(0.65, -0.65, 0.25, 1.25),
    opacity var(--transition-duration-300) cubic-bezier(0.65, -0.65, 0.25, 1.25);
}
.masonry-modal
  .image-container
  .options--container
  .group.g-1
  .dropdown-btn--container
  .dropdown--menu::after {
  --size: 12px;
  content: "";
  position: absolute;
  right: calc(0.8 * var(--size));
  bottom: calc(-0.5 * var(--size));
  transform: rotate(45deg);
  width: var(--size);
  height: var(--size);
  background: var(--black);
}
.masonry-modal
  .image-container
  .options--container
  .group.g-1
  .dropdown-btn--container
  .dropdown--menu.hide {
  opacity: 0;
  transform: scale(0.5);
}
.masonry-modal
  .image-container
  .options--container
  .group.g-1
  .dropdown-btn--container
  .dropdown--menu
  ul {
  padding: 5px 0;
}
.masonry-modal
  .image-container
  .options--container
  .group.g-1
  .dropdown-btn--container
  .dropdown--menu
  ul
  li {
  list-style: none;
  padding: 10px 0;
  width: 100%;
  cursor: pointer;
}
.masonry-modal
  .image-container
  .options--container
  .group.g-1
  .dropdown-btn--container
  .dropdown--menu
  ul
  li
  a {
  display: block;
  text-align: right;
  border-radius: 0;
  border: 0;
  background: none;
  font-weight: normal;
  font-size: 0.95rem;
  color: var(--white);
  transition: color var(--transition-duration-100) ease;
}
.masonry-modal
  .image-container
  .options--container
  .group.g-1
  .dropdown-btn--container
  .dropdown--menu
  ul
  li
  a
  span {
  color: var(--gray-light);
}
.masonry-modal
  .image-container
  .options--container
  .group.g-1
  .dropdown-btn--container
  .dropdown--menu
  ul
  li:last-child {
  border-top: 1px solid var(---gray-light);
}
.masonry-modal
  .image-container
  .options--container
  .group.g-1
  .dropdown-btn--container
  .dropdown--menu
  ul
  li:hover
  a,
.masonry-modal
  .image-container
  .options--container
  .group.g-1
  .dropdown-btn--container
  .dropdown--menu
  ul
  li:focus
  a {
  color: var(--gray-light);
}
.masonry-modal .image-container .options--container .group.g-2 button {
  padding: 0 8px;
}
.masonry-modal .image-container .options--container .group.g-2 button .icon {
  padding: 2px;
}
.masonry-modal .image-container .image-info--container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  padding: 0 2rem;
}
.masonry-modal .image-container .image-info--container .image-info--modal {
  position: relative;
  width: 550px;
  border-radius: 0.35rem;
  background-position: center;
  background-size: cover;
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .btn.modal-close {
  background: #999999;
}
.masonry-modal .image-container .image-info--container .image-info--modal .row {
  padding: 18px 25px;
  align-items: flex-end;
  justify-content: space-between;
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .row--1
  h1 {
  margin: 0;
  font-weight: 400;
  font-size: 1.5rem;
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .row--1
  span {
  font-size: 0.8rem;
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .row--2 {
  display: flex;
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .row--2
  .image-info {
  margin-right: 30px;
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .row--2
  .image-info
  .info {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .row--2
  .image-info
  .info
  .icon {
  --size: 16px;
  width: var(--size);
  height: var(--size);
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .row--2
  .image-info
  .info
  .icon
  svg {
  fill: var(--black);
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .row--2
  .image-info
  .info--title {
  font-size: 0.8rem;
  padding: 0 5px;
  font-weight: 500;
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .row--2
  .image-info
  .info--value {
  font-weight: bold;
  letter-spacing: 1px;
  padding-left: 0.4rem;
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .row--2
  .image-info
  .monthly-stats {
  font-size: 0.75rem;
  color: var(--gray-light);
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .row--2
  .image-info.likes--container
  .icon
  svg {
  stroke: var(--black);
  stroke-width: 3px;
  fill: none;
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .row--3 {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1rem;
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .row--3
  .camera-info
  .info--title {
  font-size: 0.75rem;
  color: var(--gray-dark);
  margin-bottom: 6px;
  display: block;
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .row--3
  .camera-info
  .info--value {
  font-size: 0.85rem;
}
.masonry-modal
  .image-container
  .image-info--container
  .image-info--modal
  .hr-line {
  width: 95%;
  height: 1px;
  background: var(--gray-light);
  opacity: 0.5;
  margin: auto;
}

.btn.load-more {
  border: none;
  margin: 5rem auto 20px auto;
  background: #8c8c8c;
  padding: 10px 20px;
  border-radius: 0.5em;
  color: var(--white);
  text-transform: capitalize;
  transition: background var(--transition-duration-300) ease;
  box-shadow: 0 6.7px 5.3px rgba(0, 0, 0, 0.016),
    0 22.3px 17.9px rgba(0, 0, 0, 0.024), 0 100px 80px rgba(0, 0, 0, 0.04);
}
.btn.load-more:hover {
  background: #666666;
}
.btn.load-more.loading {
  background: #666666;
  cursor: not-allowed;
}
.btn.load-more #wave {
  position: relative;
  width: 100%;
  height: 100%;
}
.btn.load-more #wave .dot {
  --size: 6px;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  margin-right: 3px;
  background: var(--white);
  -webkit-animation: wave 1.2s ease infinite;
  animation: wave 1.2s ease infinite;
}
.btn.load-more #wave .dot:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.btn.load-more #wave .dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.loading-text {
  width: 100%;
  text-align: center;
  color: var(--gray-dark);
  font-size: 2rem;
}

.failed-info-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.failed-info-container .failed-text,
.failed-info-container p {
  text-align: center;
  color: var(--gray-dark);
}
.failed-info-container .failed-text {
  font-size: 4rem;
  margin-bottom: 0.4rem;
}
.failed-info-container p {
  text-transform: uppercase;
  font-weight: 700;
}

.no-image-found-info {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: var(--gray-dark);
}
.no-image-found-info h3 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.text-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.text-info.type-something-info {
  font-size: 1.5rem;
  color: var(--gray-light);
}

.photo-detail-icons {
  display: flex;
}

.masonry-brick {
  max-width: 300px;
}

.addToCollectionsIcon {
  z-index: 50;
}

@media only screen and (max-width: 1440px) {
  .masonry {
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  }
}

@media only screen and (max-width: 1280px) {
  .masonry {
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  }
}

@media only screen and (max-width: 900px) {
  .masonry-modal .image-container {
    width: 90%;
  }
  .masonry-modal .image-container img {
    width: 100%;
  }
  .masonry {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }
}

@media only screen and (max-width: 768px) {
  .masonry {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    padding-top: 1.5rem;
  }
}

@media only screen and (max-width: 576px) {
  .masonry {
    grid-template-columns: repeat(auto-fill, minmax(47%, 1fr));
    padding-top: 1rem;
  }
}

@media only screen and (max-width: 550px) {
  header {
    position: -webkit-sticky;
    position: sticky;
    background: var(--body-background);
    z-index: 30;
    top: 0;
    padding: 0 1rem;
  }
  header form .form-group.g-1 {
    display: none;
  }
}
@media only screen and (min-width: 550px) {
  header form .form-group.g-2 {
    display: none;
  }
  header form .floating--container {
    display: none;
  }
}
@media only screen and (max-width: 400px) {
  .masonry-modal
    .image-container
    .image-info--container
    .image-info--modal
    .row--3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@-webkit-keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-100%);
  }
}
@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-100%);
  }
}
@-webkit-keyframes hue-rotation {
  0% {
    filter: hue-rotate(360deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}
@keyframes hue-rotation {
  0% {
    filter: hue-rotate(360deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}