.signup-page-wrapper {
  min-height: calc(100vh - 64px);
  padding-top: 3rem;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
}

.password-reset-wrapper,
.account-activation-wrapper {
  min-height: calc(100vh - 64px);
  padding-top: 3rem;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
}

.signup-title {
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333333;
}

.signup-sub-title {
  font-weight: 400;
  color: #333333;
  font-size: 17px;
}

.signup-form {
  margin-top: 2.5rem;
}

.pix-agreement p {
  font-size: 14px;
  color: #333333;
}

.pix-agreement p > a {
  color: #333333;
  font-weight: 600;
}

/* SIGNUP GALLERY */
.signup-gallery-wrapper {
  overflow: hidden;
  height: 100%;
}

.signup-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-auto-rows: 100px;
  grid-gap: 10px;
  margin: 0 auto;
  max-width: 960px;
  margin-top: 40px;
}

.pix-signup-card {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  transform: translateY(0);
  transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1),
    box-shadow 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.pix-signup-card:nth-child(2),
.pix-signup-card:nth-child(4),
.pix-signup-card:nth-child(7) {
  grid-row: span 2;
}

.pix-signup-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translateZ(0);
  transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.pix-signup-card:hover {
  transform: translateY(-6px);
  box-shadow: rgba(0, 0, 0, 0.5) 0 12px 24px -12px;
}
.pix-signup-card:hover:after {
  opacity: 1;
}
.pix-signup-card:hover img {
  transform: scale(1.05);
}
.pix-signup-card:hover figcaption {
  opacity: 1;
  transform: translateY(0) rotate(-3deg);
}
/* END SIGNUP GALLERY */

.signup-page {
  margin-bottom: 2rem;
}

.user-sign-page-change {
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.user-sign-page-change span > a {
  font-weight: 600;
}

.forgot-password {
  margin-bottom: 1rem;
  text-align: right;
  margin-top: -0.5rem;
  font-size: 13px;
  font-weight: 500;
  color: #007bff;
  cursor: pointer;
  float: right;
}

.forgot-password:hover {
  color: #0056b3;
  text-decoration: underline;
}

.forgot-password-popup .modal-header {
  border-bottom: none;
}

.forgot-password-popup .modal-body {
  padding-top: 0;
}

.forgot-password-request-section p,
.forgot-password-request-success-section p {
  font-size: 14px;
  color: #444444;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.back-to-page {
  display: table;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 13px;
  font-weight: 500;
  color: #007bff;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.back-to-page:hover {
  color: #0056b3;
  text-decoration: underline;
}

.password-reset-inner {
  max-width: 500px;
  margin: auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 5px;
}

.password-reset-inner p {
  font-size: 14px;
  color: #2f4f4f;
}

.password-reset-invalid {
  padding: 10px;
  background-color: #f8d7da;
  border-radius: 5px;
  margin-bottom: 1rem;
  color: #721c24;
}

.password-reset-invalid p {
  color: #721c24;
}

.signup-page .alert {
  margin-bottom: -1rem;
  margin-top: 1rem;
}

.account-activation-inner {
  text-align: center;
  max-width: 500px;
  margin: auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 5px;
}

.account-activation-inner h5 {
  font-weight: 600;
}

.account-activation-inner p {
  color: #666666;
}

.spinner-text {
  display: block;
  margin-top: 0.5rem;
}

.account-activation-inner .alert {
  margin-bottom: 0;
}

.account-activation-inner .alert p {
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.account-activation-inner .alert span {
  display: block;
  text-align: center;
  margin-bottom: 0.5rem;
}

.account-activation-inner .alert span svg {
  font-size: 35px;
}

.account-activation-inner .pix-main-button {
  width: auto;
}

.account-activation-inner .alert {
  display: table;
  width: 100%;
}

.password-input-main{
  position: relative;
}

.password-eye-icon-div {
  position: absolute;
  top: 0;
  right: 7px;
  cursor: pointer;
  height: 100%;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pix-passowrd-input {
  width: calc(100% - 34px) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.pix-passowrd-input.form-control:focus + .password-eye-icon-div {
  box-shadow: 3px 0px 0 0.1rem rgb(230 74 25 / 25%);
}

.signup-form .form-control:focus {
  box-shadow: 0 0 0 0.1rem rgb(230 74 25 / 25%)!important;
}

.google-login-btn {
  width: 100%;
  justify-content: center;
}

.facebook-login-btn {
  width: 100%;
  height: 45px;
  justify-content: center;
  font-family: Helvetica,sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: calc(.27548vw + 12.71074px);
  text-decoration: none;
  transition: background-color .3s,border-color .3s;
  background-color: #4c69ba;
  border: calc(.06887vw + .67769px) solid #4c69ba;
  padding-top: 3px;
  border-radius: 2px;
}


@media screen and (max-width: 960px) {
  .signup-gallery-wrapper {
    margin-top: 1rem;
  }

  .signup-gallery {
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .signup-page-wrapper {
    padding-top: 0;
  }
}
