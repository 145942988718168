.profile-area-main,
.public-area-album-main {
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
}

.profile-header-wrapper {
  position: relative;
  margin-bottom: 8rem;
}

.profile-user {
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 150px;
  text-align: center;
  transform: translate(-50%, -0px);
}

.profile-user-image-main {
  width: 200px;
  height: 200px;
  position: relative;
  background: #ececec;
  border-radius: 50%;
  margin: auto;
}

.profile-user-image {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border: 2px solid #212529; */
  background-color: #fffafa;
}

.profile-user-name {
  margin-top: 1rem;
  font-weight: 700;
}

.profile-area-wrapper .nav-tabs {
  border-bottom: none;
  margin-bottom: 2rem;
}

.view-album-wrapper .nav-tabs {
  border-bottom: none;
  margin-bottom: 2rem;
}

.profile-area-wrapper .nav-tabs .nav-link.active {
  border-color: transparent;
  border-bottom: 2px solid #00838f;
  color: #00838f;
  font-weight: 600;
  padding: 0.5rem 0;
}

.view-album-wrapper .nav-tabs .nav-link.active {
  border-color: transparent;
  border-bottom: 2px solid #00838f;
  color: #00838f;
  font-weight: 600;
  padding: 0.5rem 0;
}

.profile-area-wrapper .nav-tabs .nav-link {
  padding: 0.5rem 0;
  margin: 0 1rem;
  color: #4f4f4f;
}

.view-album-wrapper .nav-tabs .nav-link {
  padding: 0.5rem 0;
  margin: 0 1rem;
  color: #4f4f4f;
}

.profile-area-wrapper .nav-tabs .nav-link:first-child {
  margin-left: 0;
}

.view-album-wrapper .nav-tabs .nav-link:first-child {
  margin-left: 0;
}

.profile-area-wrapper .nav-tabs .nav-link:hover {
  border: none;
  border-bottom: 1px solid #00838f;
  color: #00838f;
}

.view-album-wrapper .nav-tabs .nav-link:hover {
  border: none;
  border-bottom: 1px solid #00838f;
  color: #00838f;
}

.pa-add-new-album-block {
  border-radius: 6px;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s;
}

.pa-add-new-album-block:hover,
.pa-available-albums-block:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-decoration: underline;
}

.pa-add-new-album-block:hover.album-name-display,
.pa-available-albums-block:hover .album-name-display {
  background-color: rgb(27 25 25 / 85%);
}

.pa-available-albums-block-inner {
  height: 100%;
}

.pa-available-albums-block {
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  background-image: url("../images//album-placeholder.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.deleted-pa-available-albums-block {
  cursor: auto;
}

.pa-available-albums-block,
.pa-add-new-album-block {
  margin-right: 1%;
  margin-bottom: 1%;
  width: 24%;
  height: 250px;
  transition: all 0.3s;
}

.pa-available-albums-block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.pa-all-albums-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.album-info-sec {
  text-align: center;
  margin: auto;
}

.album-info-sec p {
  font-size: 14px;
}

.back-to-albums-btn {
  display: block;
  color: black;
}

.view-album-wrapper {
  margin-bottom: 3rem;
}

.album-all-images-display {
  margin-top: 2rem;
  margin-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
}

.pa-add-to-album-block {
  width: 23.25%;
  height: 247px;
  margin: 0.87%;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 1rem;
  transition: all 0.3s;
  border-radius: 5%;
}

.pa-add-to-album-block:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-decoration: underline;
}

.pa-album-images-block {
  width: 23.25%;
  height: 247px;
  max-height: 247px;
  margin: 0.87%;
  position: relative;
  cursor: pointer;
  max-width: 425px;
  background: gray;
  -webkit-mask: linear-gradient(to bottom, black 0% 33%, transparent 66% 100%) 0 100%/100% 300%;
  mask: linear-gradient(to bottom, black 0% 33%, transparent 66% 100%) 0 100%/100% 300%;
  -webkit-animation: mask-slide-in 1.2s calc(0.2s + 0.1s * 1) forwards;
  animation: mask-slide-in 1.2s calc(0.2s + 0.1s * 1) forwards;
  border-radius: 6px;
}

.dropdown-arrow-hide .dropdown-toggle::after {
  display: none;
}

@-webkit-keyframes mask-slide-in {
  from {
    transform: scale(0.9) translateY(5vw);
    -webkit-mask-position: 0 100%;
    mask-position: 0 100%;
  }
  to {
    transform: scale(1) translateY(0);
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

@keyframes mask-slide-in {
  from {
    transform: scale(0.9) translateY(5vw);
    -webkit-mask-position: 0 100%;
    mask-position: 0 100%;
  }
  to {
    transform: scale(1) translateY(0);
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

.pa-album-images-block img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.album-image-state {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 4px 16px;
  border-radius: 15px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
}

.album-image-state.state-color-inreview {
  background-color: #4169e1;
}

.album-image-state.state-color-default {
  background-color: #1b1919;
}

.album-image-state.state-color-rejected {
  background-color: #ff6347;
}

.album-image-state.state-color-published {
  background-color: #219653;
}

.album-image-state.state-color-pending {
  background-color: #f2994a;
}

.statistics-overview-details {
  border: 1px solid #828282;
  border-radius: 6px;
  display: inline-block;
  margin-top: 0.5rem;
}

.statistics-overview-item {
  text-align: center;
  display: inline-block;
  padding: 10px 25px;
}

.statistics-overview-item p {
  margin-bottom: 0.5rem;
  font-size: 14px;
}

.statistics-overview-item h5 {
  margin-bottom: 0;
  font-size: 22px;
}

.statistics-income {
  margin-top: 2rem;
}

.statistics-income-details,
.payouts-main-details {
  margin-top: 1rem;
}

.statistics-income-item,
.payouts-main-item {
  background-color: #f2f2f2;
  display: inline-block;
  padding: 10px 20px;
  margin-right: 1rem;
  text-align: center;
  border-radius: 6px;
}

.statistics-income-item p,
.payouts-main-item p {
  margin-bottom: 5px;
  font-size: 14px;
}

.statistics-income-item h5,
.payouts-main-item h5 {
  margin-bottom: 0;
  color: #219653;
}

.past-payouts-wrapper {
  margin-top: 2rem;
}

.past-payouts-data-table {
  margin-top: 1rem;
}

.past-payouts-data-table .table-responsive thead {
  background-color: #303030;
  color: #ffffff;
}

.past-payouts-data-table .table-responsive thead th {
  font-weight: 500;
}

.photo-upload-popup .modal-header {
  border-bottom: none;
  display: block;
  text-align: center;
}

.photo-upload-popup .modal-header .close {
  margin-top: -43px;
  font-size: 2rem;
  padding-top: 9px;
  padding-right: 18px;
}

#newImgUpload {
  display: none;
}

.new-img-upload-label {
  position: absolute;
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  padding: 10px 58px;
  text-align: center;
  cursor: pointer;
  background-color: #ededed;
  background-image: url(../images/icons/image-upload-2.png);
  background-position: 50% 45%;
  background-size: 40px;
  background-repeat: no-repeat;
  border: 3px dotted #b9b9b9;
  transition: all 0.2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.new-img-upload-label:hover {
  border: 3px solid #e64a19;
}

.new-image-upload-section,
.upload-new-image-preview {
  position: relative;
}

.image-upload-note {
  margin-top: 0.5rem;
}

.image-upload-note p {
  margin-bottom: 7px;
  font-size: 14px;
}

.image-upload-note p a {
  color: #00838f;
}

.clear-selected-image {
  font-size: 30px;
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
  transition: 0.2s;
}

.clear-selected-image:hover {
  color: tomato;
}

.upload-new-image-btn {
  min-height: 330px;
}

.add-to-album-button {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.upload-img-btn {
  padding: 10px 60px !important;
}

.uploading-image-progressbar {
  width: 90%;
  text-align: center;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0px);
  background-color: rgb(0 0 0 / 70%);
  padding: 7px;
  border-radius: 5px;
}

.uploading-image-progressbar span {
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
  color: #ffffff;
  font-weight: 600;
}

.uploading-image-progressbar .progress-bar {
  background-color: #e64a19;
}

.uploading-image-progressbar .progress {
  height: 0.6rem;
}

.new-img-upload-label-text {
  display: block;
  font-size: 17px;
  font-weight: 600;
  color: #696969;
  margin-top: 70%;
}

.change-profile-picture {
  opacity: 0;
  transition: all 0.25s ease 0s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-profile-picture:hover {
  opacity: 1;
  transition: opacity 0.25s ease 0s;
  color: rgb(255, 255, 255);
  background: rgba(9, 30, 66, 0.36);
}

.change-header-picture {
  opacity: 0;
  transition: all 0.25s ease 0s;
  position: absolute;
  bottom: 5%;
  right: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.profile-header-main:hover .change-header-picture {
  opacity: 1;
  transition: opacity 0.25s ease 0s;
  color: rgb(255, 255, 255);
  background: rgba(9, 30, 66, 0.36);
}

.change-profile-picture .dropdown .dropdown-toggle,
.change-header-picture .dropdown .dropdown-toggle {
  border-radius: 50%;
  width: 53px;
  height: 53px;
}

.change-profile-picture .dropdown .dropdown-toggle::after,
.change-header-picture .dropdown .dropdown-toggle::after {
  display: none;
}

.change-profile-picture svg {
  font-size: 24px;
  fill: #ffffff;
}

.change-header-picture svg {
  font-size: 24px;
  fill: #ffffff;
}

.profile-header-main {
  height: 300px;
  background-color: #909090;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.album-name-display {
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 12px;
  font-size: 16px;
  margin-bottom: 0;
  background-color: #1b1919a3;
  color: #ffffff;
  border-radius: 4px;
  transition: 0.5s ease-in-out;
  
}

.upload-img-btn {
  width: auto !important;
}

.album-action-buttons button {
  margin-left: 0.5rem;
  padding: 3px 5px;
}

.album-action-buttons button svg {
  font-size: 20px;
}

.view-album-header {
  display: flex;
  justify-content: space-between;
}

.deleted-album-text {
  position: absolute;
  padding: 5px 17px;
  text-align: right;
  color: red;
  background-color: #f8d7da;
  top: 5px;
  right: 5px;
  border-radius: 32px;
  font-size: 13px;
}

.photo-details-popup .modal-header h6 {
  margin-bottom: 0;
  font-weight: 600;
}

.private-image-details {
  margin-top: 1rem;
}

.private-image-details-title {
  background: #e8e8e8;
  color: #333;
  margin: 0;
  padding: 10px;
  border-radius: 4px;
}

.private-image-details-title svg {
  vertical-align: text-top;
}

.image-details-card-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 1rem;
}

.image-details-card {
  border-radius: 4px;
  border: 1px solid rgba(26, 26, 26, 0.15);
  padding: 1.15rem;
  background: #fff;
  width: 100%;
  margin-bottom: 1.2rem;
}

.image-details-card-title {
  display: block;
  font-size: 10px;
  line-height: 19px;
  font-weight: 300;
  margin-top: 0;
  letter-spacing: 0.4px;
  margin-bottom: 15px;
  text-decoration: none;
  text-transform: uppercase;
  color: #5e5e5e;
  text-align: left;
}

.image-details-card-profile-photo {
  margin-right: 15px;
  width: 52px;
  height: 52px;
}

.image-details-card-profile-photo img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #fffafa;
}

.image-details-card-profile {
  display: flex;
}

.image-details-card-inner-title {
  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.image-details-card-inner-subtitle {
  font-size: 16px;
  line-height: 25px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  word-break: break-all;
}

.image-details-card-image-info-inner table > tr > td:first-child {
  padding: 3px 15px 3px 0;
}

.image-details-card-image-info-inner table > tr > td:last-child {
  padding: 3px 0px 3px 0;
}

.photo-info-wrapper .image-details-card-inner-title {
  margin-bottom: 0.3rem;
}

.photo-info-wrapper .image-details-card-inner-subtitle {
  margin-bottom: 0.1rem;
}

.image-details-card-main-inner {
  width: 49%;
}

.statistics-view-count {
  display: block;
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.info-view-count {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e64a19;
  margin-right: 2rem;
}

.info-view-count svg {
  margin-right: 0.5rem;
}

.statistics-download-count svg {
  margin-right: 0.3rem;
}

.statistics-download-count {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  text-align: center;
  margin-top: 0.3rem;
}

.statistics-download-count svg {
  font-size: 19px;
}

.image-details-card-image-info {
  display: flex;
  align-items: center;
}

.info-view-count-small {
  text-align: center;
  font-size: 14px;
  font-weight: 300;
}

.info-view-count-small svg {
  margin-right: 7px;
  vertical-align: middle;
}

.private-image-details-action-buttons {
  text-align: center;
  margin-top: 1rem;
}

.private-image-details-action-buttons button {
  min-width: 130px;
  margin: 0 5px;
}

.private-image-details-action-buttons button svg {
  font-size: 20px;
  margin-right: 2px;
  vertical-align: sub;
}

.image-details-card-inner-subtitle-2 {
  font-size: 13px;
  color: #4a4a4a;
}

.photo-info-wrapper {
  margin-bottom: 0.5rem;
}

.image-details-tags-display {
  margin-right: 3px;
  margin-bottom: 3px;
  padding: 1px 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  display: inline-block;
}

.image-view-simple-info {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.image-view-simple-info h6 {
  margin: 0 10px;
}

.album-spinner {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.album-all-images-display .react-loading-skeleton {
  border-radius: 0;
  line-height: inherit;
}

.image-loading-skeleton {
  margin-right: 1rem;
  height: 200px;
  width: 206px;
  margin-bottom: 1rem;
  transition: all 0.3s;
}

.photo-details-spinner {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.album-info-sec-title {
  margin-bottom: 5px;
  font-weight: 600;
}

.album-info-sec p {
  font-size: 14px;
  margin: 2px 0 0 0;
}

.view-album-header.view-public-album-header {
  display: block;
  margin-top: -24px;
}

.view-album-header.view-public-album-header .back-to-albums-btn {
  display: inline-block;
  position: relative;
  bottom: -22px;
}

.private-image-view {
  text-align: center;
}

.submit-for-review-btn {
  background-color: var(--pixPrimary) !important;
  border-color: var(--pixPrimary) !important;
  color: #fff !important;
}

.submit-for-review-btn:hover {
  background-color: #ca3f13 !important;
}

.upload-img-link {
  display: flex;
  flex-direction: row;
  justify-content: end;
  top: 11px;
  position: relative;
}

.add-photo-div {
  float: right;
}

.request-creator-btn {
  color: #067eff !important;
}

.request-creator-btn:hover {
  text-decoration: underline !important;
}

.request-creator-btn:focus {
  box-shadow: none !important;
}

.photo-upload-main {
  padding-top: 6rem;
}

.photo_upload_title {
  text-align: center;
  margin-bottom: 2rem;
}

.photo_upload_title h5 {
  font-weight: 700;
}

.about_upload_note {
  background-color: rgba(41, 121, 255, 0.15);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.about_upload_note ul{
  margin-bottom: 0;
  padding-left: 1.5rem;
}

.about_upload_note ul > li{
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.about_upload_note h6{
  font-weight: 700;
  margin-bottom: 1rem;
}

.success-message {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #008000;
  width: 100%;
  min-height: 60px;
  padding: 1rem;
  color: #ffffff;
  display: flex;
  align-items: baseline;
}

@media only screen and (min-width: 1400px) {
  .pa-available-albums-block,
  .pa-add-new-album-block {
    width: 19%;
  }
}

@media only screen and (max-width: 992px) {
  .private-image-details-action-buttons button {
    margin: 0 5px 5px;
  }
  .change-profile-picture:hover {
    background: transparent;
  }
  .change-profile-picture {
    opacity: 1;
  }
  .change-profile-picture {
    top: 83%;
    left: 83%;
  }
  .change-header-picture {
    opacity: 1;
  }
  .change-profile-picture .dropdown .dropdown-toggle,
  .change-header-picture .dropdown .dropdown-toggle {
    width: 40px;
    height: 40px;
  }
  .change-header-picture svg,
  .change-profile-picture svg {
    font-size: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .pa-available-albums-block,
  .pa-add-new-album-block {
    width: 32%;
    height: 221px;
  }

  .pa-add-to-album-block {
    width: 31.25%;
    height: 221px;
    text-align: center;
    font-size: 13px;
    margin: 1%;
  }

  .pa-album-images-block {
    width: 31.25%;
    height: 221px;
    margin: 1%;
  }

  .album-image-state {
    right: 10px;
    top: 10px;
    padding: 2px 7px;
    font-size: 11px;
  }
  .image-details-card-main-inner {
    width: 100%;
  }
  .private-image-details-action-buttons button {
    margin: 0 5px 5px;
  }
  .image-loading-skeleton {
    width: 162px;
    height: 120px;
    margin-bottom: 8px;
    margin-right: 4px;
    margin-left: 4px;
  }
}

@media only screen and (max-width: 576px) {
  .pa-available-albums-block,
  .pa-add-new-album-block {
    width: 32%;
    height: 130px;
  }
  .album-name-display {
    bottom: 0;
    padding: 4px;
    font-size: 12px;
    border-radius: 3px 3px 15px 15px;
  }
  .pa-add-new-album-block {
    text-align: center;
    font-size: 13px;
    padding: 5px;
  }
  .deleted-album-text {
    top: 5px;
    right: 50%;
    transform: translate(50%, 5px);
    text-align: center;
    width: 80%;
    padding: 3px 5px;
    font-size: 11px;
  }
  .view-album-header {
    flex-wrap: wrap;
  }
  .back-to-albums-btn {
    flex-basis: 100%;
    margin-bottom: 1rem;
    font-size: 15px;
  }
  .profile-header-wrapper {
    margin-bottom: 8rem;
  }
  .album-all-images-display {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .image-details-tags-display {
    padding: 4px;
    font-size: 12px;
  }
  .album-info-sec p {
    font-size: 12px;
  }
  .album-info-sec {
    text-align: left;
  }
  .private-image-details-action-buttons button {
    min-width: 100%;
    margin: 0 5px 5px;
  }
  .image-loading-skeleton {
    width: 107px;
    height: 120px;
    margin-bottom: 8px;
    margin-right: 4px;
    margin-left: 4px;
  }
  .profile-user-image-main {
    width: 150px;
    height: 150px;
  }
  .profile-header-main {
    height: 230px;
  }
  .profile-user-name {
    font-size: 16px;
  }

  .upload-img-link {
    top: 49px;
    flex-direction: row;
    justify-content: start;
    top: 0;
    position: relative;
  }

  .add-photo-div {
    float: inherit;
  }
}

@media (min-width: 401px) and (max-width: 768px) {
  .pa-add-to-album-block {
    height: 144px;
  }

  .pa-album-images-block {
    height: 144px;
  }
}

@media (max-width: 400px) {
  .pa-add-to-album-block {
    width: 47%;
    margin: 1.5%;
    height: 120px;
  }

  .pa-album-images-block {
    width: 47%;
    height: 120px;
    margin: 1.5%;
  }

  .upload-img-link {
    display: flex;
    flex-direction: row;
    justify-content: start;
    top: 0;
    position: relative;
  }
}
