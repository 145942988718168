:root {
  --pixPrimary: #e64a19;
  --borderRadius: 6px;
}

body {
  color: #333333;
}

button[disabled]:active,
button[disabled],
button[disabled]:hover {
  cursor: inherit !important;
  border: 1px solid #b3b3b3 !important;
  border-color: #b3b3b3 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  background: #ddd !important;
}

.home-main-wrapper {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  /* padding: 1.15rem; */
  padding-top: 74px;
  padding-bottom: 66px;
  color: #fff;
  background-color: #d3d3d3;
  text-decoration: none;
  height: 70vh;
  min-height: 380px;
  z-index: 1;
  overflow: hidden;
}

.carousel-root {
  position: absolute;
  width: 100%;
}

.home-main-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2))),
    -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(transparent));
  z-index: 2;
}

#search {
  position: relative;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
  padding: 0.7em 0.5em 0.7em 1em;
  transition: all 0.5s;
  color: #222222;
  transition: visibility 0.3s;
}

#nav-search {
  position: relative;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  margin-left: 2rem;
  justify-content: space-between;
  padding: 0.3em 0.3em 0.3em 0.6em;
  transition: all 0.5s;
  width: 50%;
  color: #222222;
  border: 1px solid #b4b4b4;
}

#search.image-search-error {
  border: 1px solid rgb(220 53 69 / 43%) !important;
}

#search button,
#search input,
#nav-search button,
#nav-search input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  outline: 0;
}

#search button,
#nav-search button {
  cursor: pointer;
  padding: 11px 15px;
  font-size: 20px;
  color: #5e5e5e;
  margin: -9px -8px -11px -10px;
}

#search input,
#nav-search input {
  flex: 1;
  font-size: 20px;
}

#search .spinner,
#nav-search .spinner {
  -webkit-animation: spinner 1s infinite linear;
  animation: spinner 1s infinite linear;
  display: none;
  padding: 0 0.25em;
}

#search.loading button,
#nav-search.loading button {
  display: none;
}

#search.loading .spinner,
#nav-search.loading .spinner {
  display: block;
  color: #808080;
  line-height: 35px;
  font-size: 15px;
  padding: 0 15px;
  cursor: pointer;
}

.search-tags-results-div .search-tag-links:hover {
  background: #e8e8e8;
  text-decoration: none;
  color: #333333;
}

/* #search .suggest {
  width: 100%;
}

#search .suggest .suggest__datalist {
  color: #717171;
  cursor: pointer;
  line-height: 26px;
}

#search .suggest .suggest__datalist {
  color: #717171;
  cursor: pointer;
  line-height: 26px;
}

#search .suggest .suggest__datalist * {
  padding-top: 5px;
}

#search .suggest .suggest__datalist *:hover {
  color: #333333;
} */

.search-tags-results-div {
  position: absolute;
  top: 52px;
  left: 0;
  background: #fff;
  width: 100%;
  padding: 10px 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  max-height: 290px;
  overflow-y: auto;
  box-shadow: 0 16px 22px -10px rgba(0, 0, 0, 0.15), 0 34px 55px 4px rgba(0, 0, 0, 0.08), 0 13px 66px 12px rgba(0, 0, 0, 0.07);
}

#nav-search .search-tags-results-div {
  top: 41px;
}

.search-tags-results-div .search-tag-links {
  display: block;
  color: #808080;
  line-height: 35px;
  font-size: 15px;
  padding: 0 15px;
  cursor: pointer;
}

.search-tags-results-div .search-tag-links:hover {
  background: #e8e8e8;
  text-decoration: none;
  color: #333333;
}

@-webkit-keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.search-main-wrapper {
  z-index: 100;
  width: 650px;
}

#search button svg,
#nav-search button svg {
  vertical-align: -3px;
}

.discover-more-wrapper {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.button-outlined-regular {
  display: inline-block;
  border: 2px solid #e64a19;
  color: #e64a19;
  padding: 13px 60px;
  outline: 0;
  background-color: transparent;
  font-size: 14px;
  border-radius: var(--borderRadius);
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background 0.15s ease, line-height 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.button-outlined-regular:hover {
  background-color: #e64a19;
  color: #fff;
}

.button-outlined-regular:focus {
  background-color: #be3930;
}

.button-regular {
  display: inline-block;
  background-color: #e64a19;
  color: #fff;
  padding: 13px 60px;
  border: 0;
  outline: 0;
  font-size: 14px;
  border-radius: var(--borderRadius);
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background 0.15s ease, line-height 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.button-regular:hover {
  background-color: #f44336;
}

.button-regular:focus {
  background-color: #be3930;
}

.about-desc {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.join-us-wrapper {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.join-us-wrapper button {
  margin-top: 1.5rem;
}

.footer-wrapper {
  /* background-image: url(../images/footer-bg.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 3rem 0;
  color: #ffffff;
  margin-top: auto;
  background: #303030;
}

footer {
  position: relative;
}

.footer-overlay:after {
  content: "\A";
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  transition: all 1s;
  -webkit-transition: all 1s;
}

.footer-wrapper .container {
  z-index: 1;
}

.footer-logo img {
  margin-right: 1rem;
  width: 130px;
}

.footer-logo {
  font-size: 24px;
  font-weight: 700;
}

.footer-desc {
  margin-top: 1rem;
}

.footer-link-list ul {
  padding: 0;
  list-style: none;
  margin-top: 1rem;
}

.footer-link-list ul > li > a {
  color: #ffffff;
  margin: 0.5rem 0;
  display: block;
}

.footer-title {
  font-size: 19px;
  text-transform: uppercase;
}

.footer-copyright {
  text-align: center;
  margin-top: 2rem;
  font-weight: 400;
}

.footer-copyright-simple {
  text-align: center;
  font-weight: 400;
}

.footer-copyright a,
.footer-copyright-simple a {
  color: #ffffff;
}

/* best web logo */
.bestweb-logo {
  position: absolute;
  bottom: 30px;
  right: 40px;
  z-index: 99999999;
}

.thumb-inner-img {
  display: block;
  width: 100%;
  max-height: 386px;
  height: 100%;
  object-fit: contain;
  object-position: top;
  background: #e0e0e0;
}

.image_info_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.share-photo-button {
  background-color: transparent !important;
  border: none !important;
  color: #2979ff !important;
  font-weight: 600 !important;
}

.share-photo-button:hover {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
  font-weight: 600 !important;
}

.share-profile-header {
  margin: auto;
  width: 100%;
  text-align: center;
  margin-top: -3rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #d3d3d3;
}

.photo-share-popup .modal-header {
  z-index: 100;
  border-bottom: none;
}

.share-profile-header img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.copy_link_btn.copied-btn:hover svg {
  color: #212529;
}

.photo_by_share {
  font-size: 1rem;
  margin-top: 0.5rem;
}

.photo_by_share span {
  font-weight: 600;
}

.heade_photo_title {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.social_item img {
  width: 40px;
  height: auto;
  margin-bottom: 8px;
}

.social_item h6 {
  font-size: 0.8rem;
  display: block;
  font-weight: 600;
  margin-bottom: 0;
}

.social_wrapper {
  display: flex;
  gap: 2px;
  margin-top: 1rem;
  margin-bottom: 1.1rem;
}

.social_item {
  width: 68px;
  text-align: center;
  cursor: pointer;
  padding: 7px 2px;
  transition: ease-in-out 0.1s;
}

.social_item:hover {
  background-color: #e0e0e0;
  border-radius: 3px;
}

.copy_link_wrapper {
  position: relative;
}

.copy_link_btn {
  position: absolute;
  right: 4px;
  top: 4px;
  background-color: #ffffff !important;
  padding-top: 9px !important;
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  min-width: 62px;
}

.copy_link_btn svg {
  font-size: 22px;
  color: #ffffff;
}

.copied-btn {
  background-color: #008000 !important;
}

.copy_link_btn:hover {
  background-color: #008000 !important;
}

.copy_link_btn:hover svg {
  color: #ffffff !important;
}

.copy_link_wrapper .form-control {
  height: calc(2em + 0.75rem + 2px);
  padding-right: 73px;
}

.share_section_title {
  display: block;
  font-size: 1rem;
  font-weight: 600;
}

.share_section_title_link {
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 8px;
  color: #5e5e5e;
}

/* Tooltip */
.pix-tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: #222222;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  top: -44px;
  right: 6px;
  position: absolute;
  z-index: 1;
  font-size: 0.8rem;
}

.pix-tooltip:hover .tooltiptext {
  visibility: visible;
}

.pix-tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #222222 transparent transparent transparent;
}

.fill_image_loading {
  display: block;
  width: 100%;
  height: 400px;
  background-color: #f2f2f2;
  position: relative;
}

@media (max-width: 1300px) {
  .bestweb-logo {
    bottom: 60px;
    width: 130px;
    height: 130px;
  }
}

@media (max-width: 575px) {
  .bestweb-logo {
    bottom: 40px;
    right: 20px;
    width: 80px;
    height: 80px;
  }
}

.bestweb-logo-upper {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  right: 40px;
  z-index: 99999999;
}

@media (max-width: 1300px) {
  .bestweb-logo-upper {
    width: 130px;
    height: 130px;
  }
}

@media (min-width: 331px) and (max-width: 480px) {
  .home-main-wrapper {
    height: 80vh;
  }

  .bestweb-logo-upper {
    top: 85%;
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 330px) {
  .home-main-wrapper {
    height: 100vh;
  }

  .bestweb-logo-upper {
    top: 89%;
    width: 80px;
    height: 80px;
  }
}

.main-wrapper-title {
  display: block;
  font-size: 35px;
  line-height: 48px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 15px;
}

.pix-main-button {
  background-color: var(--pixPrimary) !important;
  border-color: var(--pixPrimary) !important;
  width: 100%;
  font-weight: 500 !important;
}

.pix-main-button:hover {
  background-color: #ca3f13 !important;
  text-decoration: unset !important;
  color: #ffffff !important;
}

.pix-main-button:focus {
  box-shadow: 0 0 0 0.2rem rgb(230 74 25 / 25%) !important;
}

.form-control {
  color: #000000 !important;
  background-color: #e0e0e0 !important;
  border: none !important;
}

.form-control:focus {
  color: #000000 !important;
  background-color: #e0e0e0 !important;
  border-color: #e64a19 !important;
  box-shadow: 0 0 0 0.2rem rgb(230 74 25 / 25%) !important;
}

.rbt-input-multi.focus {
  border-color: #e64a19 !important;
  box-shadow: 0 0 0 0.2rem rgb(230 74 25 / 25%) !important;
  color: #000000 !important;
  outline: 0;
}

.span-link {
  color: #00838f;
  cursor: pointer;
}

.span-link:hover {
  color: #00bbcc;
  text-decoration: underline;
}

.pix-main-button .spinner-border {
  margin-right: 0.5rem;
  vertical-align: middle;
}

label.form-label {
  font-size: 14px;
  font-weight: 500;
  color: #555555;
}

.alert svg {
  margin-right: 5px;
  font-size: 23px;
}

.alert {
  font-size: 14px;
}

.btn-normal-width {
  width: auto !important;
}

.action-buttons {
  text-align: right;
}

.action-buttons button {
  margin-left: 1rem;
}

.image-upload-or {
  color: black;
  margin-bottom: 5px;
}

.action-buttons-center {
  text-align: center;
}

.action-buttons-center button {
  margin: 0 5px;
  min-width: 90px;
}

.confirm-action-dialog .modal-header svg {
  font-size: 2.5rem;
  color: #dc3545;
}

.confirm-action-dialog .modal-header {
  display: block;
  text-align: center;
}

.confirm-action-dialog .modal-header h4 {
  margin-top: 1rem;
  color: #dc3545;
  font-size: 20px;
  font-weight: 500;
}

.confirm-action-dialog .modal-body {
  background-color: #f8f8f8;
}

.confirm-action-dialog .modal-body .confirm-dialog-meg {
  margin: 1rem 0 1.5rem 0;
  text-align: center;
}

.remove-profile-images.dropdown-item {
  color: #ff0000;
}

.pix-logo {
  width: 130px;
  padding-left: 12px;
}

.pix-logo img {
  width: 100%;
}

.home-page-loading-spinner {
  color: #e64a19 !important;
}

.spinner-border {
  border-width: 0.15em !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: unset !important;
}

.about_upload_note a {
  color: #212529;
  text-decoration: underline;
}

/* breadcrumb */
.breadcrumb {
  margin-bottom: 0 !important;
  background-color: transparent !important;
  padding-left: 0 !important;
}

.breadcrumb li {
  font-size: 14px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "> " !important;
}

.breadcrumb-item a {
  color: rgb(48, 48, 48);
  font-weight: 400;
  text-decoration: none;
}

.breadcrumb-item a:hover {
  color: #075f92;
  text-decoration: underline;
}

.terms-section ol li {
  list-style: decimal;
  margin: 2rem 0;
}

.terms-section ol li ul > li {
  list-style: disc;
  margin: 1rem 0;
}

.non-styles {
  padding: 0;
}

.non-styles > li {
  list-style: none !important;
}

.terms-div h2 {
  font-size: 18px;
  font-weight: 600;
  color: #404040;
  margin: 1rem 0 0.75rem;
}

.terms-section ol.alpha {
  margin: 1rem 0;
}

.terms-section ol.alpha li {
  list-style: lower-alpha;
  margin: 1rem 0;
}

.privacy-section {
  margin-bottom: 2rem;
}

.terms-div {
  color: #404040;
  line-height: 28px;
  text-align: justify;
}

.terms-heading {
  font-size: 2.125rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  text-align: center;
}

.terms-sub-heading {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  text-align: center;
  margin-bottom: 35px;
}

.privacy-div h2 {
  font-size: 18px;
  font-weight: 600;
  color: #404040;
  margin: 1rem 0 0.75rem;
}

.privacy-div {
  color: #404040;
  line-height: 28px;
  text-align: justify;
}

.privacy-heading {
  font-size: 2.125rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  text-align: center;
}

.privacy-sub-heading {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  text-align: center;
  margin-bottom: 35px;
}

.image-upload-container p {
  margin-bottom: 5px;
}

.image-upload-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.image-upload-container .btn-primary {
  min-width: 120px;
}

.thumbs-container {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin-bottom: 3rem;
}

.thumb {
  width: 50%;
  position: relative;
}

.image-details-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image-details-container .pix-main-button {
  width: auto;
  align-self: flex-start;
}

.error-message {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #eb5757;
  width: 100%;
  min-height: 60px;
  padding: 1rem;
  color: #ffffff;
  display: flex;
  align-items: baseline;
}

.error-message h6 {
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.error-message p {
  margin: 0;
  font-size: 0.95rem;
  font-weight: 600;
}

.upload-status-icon {
  margin-right: 10px;
}

.upload-status-icon svg {
  width: 30px;
  height: 30px;
}

.success-message p {
  margin-bottom: 0;
}

.image-details-container .pix-main-button {
  margin-top: 1rem;
}

.textarea.form-control {
  min-height: 85px;
}

.thumb-inner {
  height: auto;
  max-height: 386px;
}

.checkbox-info.form-label {
  margin-bottom: 0;
}

@media only screen and (max-width: 990px) {
  .footer-wrapper {
    text-align: center;
  }

  .footer-title {
    visibility: hidden;
  }
}

@media only screen and (max-width: 768px) {
  .footer-quicklinks {
    margin-top: 2rem;
  }
  .menu-icon {
    transform: translate(-100%, 0%);
  }
  .nav-menu {
    height: calc(100vh - 56px);
    top: 56px;
  }
  #nav-search {
    position: absolute;
    margin-left: 0;
    top: 41px;
    width: 100%;
    left: 0;
    border-color: #222222;
    border-width: 5px;
    border-style: solid;
    border-radius: 5px;
  }
  .pix-logo {
    width: 100px;
  }

  .thumbs-container {
    grid-gap: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #d3d3d3;
  }

  .image-details-container .form-group {
    margin-bottom: 0.4rem;
  }

  .image-details-container {
    width: 100%;
  }

  .thumb {
    width: 100%;
  }

  .thumb-inner {
    height: 185px;
  }

  .image-details-container .pix-main-button {
    margin-top: 0.4rem;
  }

  .image-details-container .pix-main-button {
    align-self: flex-end;
  }

  .image-upload-container {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .photo-upload-main {
    padding-top: 5rem;
  }

  .carousel.carousel-slider,
  .carousel-root,
  .carousel .slider-wrapper,
  .carousel .slider,
  .pix-carousel-item {
    height: 100%;
  }

  .carousel .slide img {
    height: 100%;
    object-fit: cover;
  }

  .search-main-wrapper {
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 576px) {
  .footer-wrapper {
    text-align: center;
    padding: 1rem 0;
  }
  .footer-copyright-simple.col {
    font-size: 14px;
  }
  #search input,
  #nav-search input {
    font-size: 16px;
  }
  .menu-icon {
    transform: translate(-100%, 0%);
  }
  .nav-menu {
    height: calc(100vh - 44px);
    top: 44px;
  }
  .footer-logo img {
    margin-right: 0;
  }

  .main-wrapper-title {
    font-size: 28px;
    line-height: 32px;
  }
}
